import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue'),
    redirect: '/englishword',
    children: [{
        path: '/word',
        name: 'Word',
        component: () => import( /* webpackChunkName: "about" */ '../views/home/Word.vue')
    },
        {
            path: '/sentence',
            name: 'Sentence',
            component: () => import( /* webpackChunkName: "about" */ '../views/home/Sentence.vue')
        },
        {
            path: '/englishword',
            name: 'Englishword',
            component: () => import( /* webpackChunkName: "about" */ '../views/home/Englishword.vue')
        },
        {
            path: '/personaldata',
            name: 'PersonalData',
            component: () => import( /* webpackChunkName: "about" */ '../views/home/PersonalData.vue')
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import( /* webpackChunkName: "about" */ '../views/home/Login.vue')
        }
    ]
},
    {
        path: '/manage',
        name: 'Manage',
        component: () => import( /* webpackChunkName: "about" */ '../views/Manage.vue'),
        redirect: '/userinfo',
        children: [{
            path: '/userinfo',
            name: 'UserInfo',
            component: () => import( /* webpackChunkName: "about" */ '../views/manage/UserInfo.vue')
        },
            {
                path: '/usertypinginfo',
                name: 'UserTypingInfo',
                component: () => import( /* webpackChunkName: "about" */ '../views/manage/UserTypingInfo.vue')
            },
            {
                path: '/worddata',
                name: 'WordData',
                component: () => import( /* webpackChunkName: "about" */ '../views/manage/WordData.vue')
            },
            {
                path: '/sentencedata',
                name: 'SentenceData',
                component: () => import( /* webpackChunkName: "about" */ '../views/manage/SentenceData.vue')
            },
            {
                path: '/englishworddata',
                name: 'EnglishwordData',
                component: () => import( /* webpackChunkName: "about" */ '../views/manage/EnglishwordData.vue')
            },
            {
                path: '/wordrecord',
                name: 'WordRecord',
                component: () => import( /* webpackChunkName: "about" */ '../views/manage/WordRecord.vue')
            },
            {
                path: '/sentencerecord',
                name: 'SentenceRecord',
                component: () => import( /* webpackChunkName: "about" */ '../views/manage/SentenceRecord.vue')
            },
            {
                path: '/englishwordrecord',
                name: 'EnglishwordRecord',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/manage/EnglishwordRecord.vue')
            },
            {
                path: '/fontNumberSetting',
                name: 'FontNumberSetting',
                component: () => import( /* webpackChunkName: "about" */
                    '../views/manage/FontNumberSetting.vue')
            },
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router


//https://blog.csdn.net/chenjun1992/article/details/117572522
//解决重定向之后还是同一个界面报错的问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
