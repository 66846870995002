import axios from 'axios'

// 创建 axios 实例
const request = axios.create({
	baseURL: 'http://120.46.165.45:8081',
	// baseURL: 'http://172.21.97.78:8081',
	// baseURL: 'http://localhost:8081',
	// baseURL: 'http://110.42.174.249:8081',
	timeout: 60000 // 请求超时时间  6s太短  还超时了 垃圾服务器
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
	let user = localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")) : {}
    if(user) {
		config.headers['token'] = user.token;  // 设置请求头
	}
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        //如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default request