import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './static/gloabl.css'
import request from "./utils/axios"

Vue.use(ElementUI, {
	size: "small"
});
Vue.prototype.request = request

//消息提醒时间改成1.5秒
Vue.prototype.$message = function(msg) {
	ElementUI.Message({
		message: msg,
		duration: 1500
	})
}

Vue.prototype.$message.error = function(msg) {
	return ElementUI.Message.error({
		message: msg,
		duration: 2500
	})
}
Vue.prototype.$message.warning = function(msg) {
	return ElementUI.Message.warning({
		message: msg,
		duration: 1500
	})
}
Vue.prototype.$message.success = function(msg) {
	return ElementUI.Message.success({
		message: msg,
		duration: 1500
	})
}

// 禁用右键
window.addEventListener('contextmenu', function(e) {
	e.preventDefault();
});
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
